import React from 'react';
import Features from './Features';
import Header from './Header';
import logo from './logo.png';

function App() {
  return (
    <div className="p-6">
      <Header />
      <Features />
    </div>
  );
}

export default App;
