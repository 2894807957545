import React from 'react'

function Features() {
    return (
        <div>
            <div className="py-12 bg-white">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-24">
                    <div className="lg:text-center">
                        <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Indian Institute of Waste Management and Research Centre</h2>
                        <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                          Reduce, Reuse, Recycle and Recover
                        </p>
                        <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                        </p>
                    </div>

                    <div className="mt-10">
                        <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-1 md:mt-4 md:gap-x-8 md:gap-y-10">
                            <div className="relative">
                                <dt className="mt-8">
                                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                                        </svg>
                                    </div>
                                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Vision</p>
                                </dt>
                                <dd className="mt-2 ml-16 text-base text-gray-500">
                                    Motivate and empower people to attain a clean and healthy world
                                </dd>
                            </div>

                            <div className="relative">
                                <dt  className="mt-8">
                                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                                        </svg>
                                    </div>
                                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Mission</p>
                                </dt>
                                <dd className="mt-2 ml-16 text-base text-gray-500">
                                    To enrich people with the awareness about the importance of waste management issues and develop analytical research and educational programs that protect our environment and improve the quality of life. The programs nurture the next generation with values, principles, and solutions for waste-related concerns.
                                </dd>
                            </div>

                            <div className="relative">
                                <dt  className="mt-8">
                                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                                        </svg>
                                    </div>
                                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Scope</p>
                                </dt>
                                <dd className="mt-2 ml-16 text-base text-gray-500">
                                    It is clear from our past activities that an educational institution is a must in waste disposal and management. The devastation of our habitat due to improper waste treatment is a threat to the entire humankind. Unscientific waste management poses a significant threat to planet earth and, therefore, human life.
                                    It has been noted that the significant issue in the waste management area is the lack of awareness, ignorance, and neglected nature of the public about the scientific way of waste disposal. Apart from these, the availability of feasible and scientific methods of waste treatment is also a severe issue pertinent to the current problems.

                                    We aim to develop awareness and culture in the society through educational interventions at school, college, and community levels to develop a healthy attitude towards waste disposal and treatment. We also aimed to formulate futuristic methods and technologies to convert the waste into value-added items that can be re-used.
                                    We also set a target of preparing projects, training professional students and policymakers to develop protocols for the waste management process at government and non-government levels. We are working to implement the concept of “Reduce, Recycle and Re-use.”
                                </dd>
                            </div>

                            <div className="relative">
                                <dt  className="mt-8">
                                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                                        </svg>
                                    </div>
                                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Activities</p>
                                </dt>
                                <dd className="mt-2 ml-16 text-base text-gray-500">
                                    1. Develop educational and training programs for school and college students. <br/>
                                    2. Develop and conduct training programs for stakeholders. <br/>
                                    3. Internship programs for students and professionals in waste management.<br/>
                                    4. Quantitative and qualitative research programs to find solutions for scientific waste management.<br/>
                                    5. Conceptualising new and innovative projects.<br/>
                                    6. Provide training and support to new entrepreneurs and help them to start their own ventures in waste management sector.                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Features
